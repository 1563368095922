$(document).ready(function(){
    $("main").on("click",".scroll", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 1500);
    });

    $('a.more').click(function(e) {
        if ( $(this).hasClass('collapsed')){
            $(this).children().text('Скрыть');
        } else {
           $(this).children().text('Подробнее');
        }
    });

    $('a.popup').click(function(e) {
        var title = $(this).attr('data-title');
        $('#form-name').val(title);
    });

    $( "form" ).on( "submit", function( event ) {

        $.ajax({
        type: "POST",
        url: "/send.php",
        data: $( this ).serialize(),
        success: function(){
            alert('Сообщение успешно доставно');
        },
        error: function(){
            alert('Что-то пошло не так. Попробуйте снова.');
        }
        });
        return false;
        });
  

});

function windowSize(){
    if ($(window).width() < '768'){
      $(document).ready(function() {
          $('.certifikats').not('.slick-initialized').slick({
              centerMode: true,
              centerPadding: '60px',
              slidesToShow: 2,
              arrows: false,
              responsive: [
              {
                  breakpoint: 1920,
                  settings: 'unslick'
              },
              {
                  breakpoint: 768,
                  settings: {
                  slidesToShow: 3
                  }
              },
              {
                  breakpoint: 600,
                  settings: {
                  slidesToShow: 2
                  }
              },
              {
                  breakpoint: 500,
                  settings: {
                  slidesToShow: 1
                  }
              }
              ]
          });
      });
    }  else{
        //$('.certifikats').slick('unslick');
    }
  }
  
  $(window).on('load resize',windowSize);